import { breakpoints, debounce } from "./utilities.js";
let videoPositionData = {};

const getDeviceType = () => {
    if (!videoPositionData || !videoPositionData.VideoPositions) {
        return false;
    }

    const width = window.innerWidth;
    let device = (width >= breakpoints.tablet && width < breakpoints.desktop) ? "Tablet" :
        (width >= breakpoints.desktop && width < breakpoints.large) ? "Desktop" :
            (width >= breakpoints.large && width < breakpoints.exlarge) ? "Large" : "ExLarge";

    if (device === "Tablet") {
        if (window.matchMedia("(orientation:portrait)").matches) {
            device = "TabletP";
        } else {
            device = "TabletL";
        }
    }

    const videoPosition = videoPositionData.VideoPositions[device];
    if (!videoPosition) {
        return false;
    }

    document.querySelectorAll('.m-banner-hero__video__iframe').forEach(function(e) {
        e.style.left = (videoPosition.left ?? 0) + '%';
        e.style.top = (videoPosition.top ?? 0) + '%';
    });

    document.querySelectorAll('.m-banner-hero__video').forEach(function(e) {
        if (videoPosition.scale) {
            e.style.width = (videoPosition.scale ?? 100) + '%';
        }
    });
}

const init = (positions) => {
    videoPositionData = positions;
    getDeviceType();
    window.addEventListener("resize", debounce(getDeviceType), { 'passive': true });
}

export default init;
